import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HeadTopBar from "../../includes/HeadTopBar";
import instance from "../../../axios/axios";
import { deleteAboutFeature, editAboutDetails, listAboutFeature, viewAboutDetails, } from "../../../axios/api";
import Swal from "sweetalert2";
import AddFeatureModal from "../../modal/about/AddFeatureModal";
import CircularProgress from "@mui/material/CircularProgress";


function About() {
  const [formData, setformData] = useState({});
  const [isModal, setisModal] = useState(false);
  const [features, setfeatures] = useState(null);
  const [state, setstate] = useState(false);
  const [obj, setobj] = useState(null);
  
  useEffect(() => {
    instance.get(listAboutFeature)
    .then((response)=>{
      const { StatusCode , data }  = response.data.app_data;
      
      if(StatusCode === 6000){
         setfeatures(data);
      }else if(StatusCode === 6001){
        setfeatures([]);
      }
    })

    instance.get(viewAboutDetails)
    .then((response)=>{
       const { StatusCode , data} = response.data.app_data;
       
       if(StatusCode === 6000){
          setformData({
            ...data,
            'preview':data.image,
            'image':''
          })
          setobj(data);
        }
    });
  }, [isModal,state])

  const handleChange = (e) => {
      const { name} = e.target;
       
      if(name === 'image'){
        setformData({
          ...formData,
          [name]:e.target.files[0],
          'preview':URL.createObjectURL(e.target.files[0])
        })
      }else{
        setformData({
          ...formData,
          [name]:e.target.value
        })
      }
  }
   
  const onSubmit = (e) =>{
    const form = new FormData();
      Object.entries(formData).forEach((obj)=>{
        
        form.append(obj[0],obj[1]);
      })

    instance.post(editAboutDetails,form,{
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response)=>{
      const { StatusCode, data } = response.data.app_data;
      if(StatusCode === 6000){
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'About Info Edited!',
          showConfirmButton: false,
          timer: 1500,
          height:100,
          width:400,
          fontSize:"14px",
        })
          setformData({})
          setstate(!state)
      }else{
        
      }

    })
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Do you want to delete the feature?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        instance.delete(deleteAboutFeature+id)
        .then((response)=>{
          const { StatusCode , data} = response.data.app_data;
          if(StatusCode === 6000){
            setstate(!state)
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'feature Deleted !',
              showConfirmButton: false,
              timer: 1500,
              width:600,
            })
          }else if(StatusCode === 6001){
              
          }
        })
      }
    })
  };

  return (
    <Container>
      <Wrapper className="wrapper">
        <HeadTopBar />
        <ProgramSection>
          <TopSection>
            <Title>About - Content</Title>
          </TopSection>
          {
            obj === null ? 
            <Loader>
              <CircularProgress/>
            </Loader> :
             (
              <Section>
            <Cover>
              <Label>Title</Label>
              <Input type="text"
               placeholder="Enter the title"
               value={formData?.title}
               name="title"
               onChange={(e)=>handleChange(e)}
               />
            </Cover>
            <Cover>
              <Label>Content</Label>
              <TextArea placeholder="Content"
              value={formData?.content}
              name="content"
              onChange={(e)=>handleChange(e)}
              ></TextArea>
            </Cover>
            <Cover>
              <Label>Mission</Label>
              <TextArea placeholder="Enter the Mission Content"
               value={formData?.mission}
               name="mission"
               onChange={(e)=>handleChange(e)}
              ></TextArea>
            </Cover>
            <Cover>
              <Label>Vision</Label>
              <TextArea placeholder="Enter the Vision Content"
               value={formData?.vission}
               name="vission"
               onChange={(e)=>handleChange(e)}
              ></TextArea>
            </Cover>
            <Cover>
             <Label>Image ( 2000 x 1125) </Label>
             <PreviewArea>
             <Img> 
               <img src={formData?.preview} alt="" />
             </Img>
             <Input
               type="file"
               name="image"
               onChange={(e)=>handleChange(e)}
             />
             </PreviewArea>
            </Cover>
            <Submit>
                <SubmitButton 
                onClick={onSubmit}
                >
                    Update
                </SubmitButton>
            </Submit>
          </Section>
             )
          }
          
          <Section>
          <Title>Features</Title>
          <AddFeature>
             <button onClick={()=>{
                setisModal(true)
             }}>
                Add Feature
             </button>
          </AddFeature>
          <Features>
            {
              features === null ?
              <Loader>
                <CircularProgress/>
              </Loader> : 
              (
                features.length === 0 ? (
                  <Feature>
                      <p>
                        No Data Found
                      </p>
                  </Feature>
                ) : (
                    features.map((obj,indx)=>{
                      return (
                        <Feature>
                        <span>
                          {indx+1}
                        </span>
                        <p>
                          {
                            obj.feature
                          }
                        </p>
                        <button 
                         onClick={()=>handleDelete(obj.id)}
                        >
                            Delete
                        </button>
                    </Feature>
                      )
                    })
                )
              )

            }
          </Features>
          <AddFeatureModal isModal={isModal} setModal={setisModal}/>
          </Section>
        </ProgramSection>
      </Wrapper>
    </Container>
  );
}

export default About;

const Container = styled.div`
  padding: 80px 0;
  color: var(--textcolor);
`;
const Wrapper = styled.div``;

const ProgramSection = styled.div`
  margin-top: 40px;
`;
const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;
const Title = styled.h3`
  color: #fff;
`;
const Button = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  color: var(--lightblue);
  height: 40px;
  width: 150px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
const Cards = styled.div``;
const Card = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-bottom: 10px;
`;
const Number = styled.div`
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
  width: 10%;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
`;
const TextBar = styled.div`
  display: flex;
  width: 20%;
  padding: 0 40px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--darkgrey);
  border: 1px solid var(--bordercolor);
`;
const Icon = styled.div`
  width: 18px;
  cursor: pointer;
`;
const Edit = styled.div`
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--lightblue);
`;

const Image = styled.div`
  width: 30%;
  background-color: var(--darkgrey);
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
    display: block;
  }
`;
const Status = styled.div`
  width: 20%;
  border-radius: 8px;
  border: 1px solid var(--bordercolor);
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.active === true
      ? "background-color: var(--lightblue);color:#fff;"
      : "background-color: var(--darkgrey);color:var(--lightblue);"};
`;

const Section = styled.div``;

const Cover = styled.div`
  display: flex;
  margin: 15px 0px;
`;

const Label = styled.div`
  color: var(--textcolor);
  width: 40%;
`;
const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  height: 50px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const TextArea = styled.textarea`
  padding: 10px 20px;
  width: 100%;
  height: 120px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const PreviewImg = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #5b5b5b;
  border: none;
  outline: none;
  color: #fff;
`;

const Submit = styled.div`
 display: flex;
 margin: 15px 0px;
 align-items: flex-end;
 justify-content:flex-end;
`;

const SubmitButton = styled.button`
   background-color: var(--lightblue);
    color: var(--blue);
    width: 150px;
    height: 40px;
    font-family: "mont-medium";
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PreviewArea = styled.div`
    width: 100%;
`;

const Img = styled.div`
      width:85%;
      margin: 10px 0px;
      img{
        display: block;
        width: 100%;
      }
`;


const Features = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin:15px 0px;
    background-color: #5b5b5b;
    padding:10px 5px;
    width:100%;
    border-radius:10px;
`;

const Feature = styled.div`
 display: flex;
 justify-content: space-around;
 align-items: center;
 width:95%;
 color:#fff;
 border:1px solid var(--lightblue);
 border-radius: 10px;
 padding: 10px 5px;
 margin: 0 auto;
 
 button{
    width:30%;
    color:#fff;
    background-color: var(--lightblue);
    border-radius: 10px;
    outline: none;
    border:none;
    padding:15px 20px;
    cursor: pointer;
 }
 span{
    padding:15px 20px;
    border: 1px solid #fff;
    border-radius: 10px;
 }
 p{
    width:50%;
 }
`;

const AddFeature = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    button{
        color:#fff;
        background-color: var(--lightblue);
        border-radius: 10px;
        outline: none;
        border:none;
        padding: 15px 20px;
    }
`;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50vh;
`;