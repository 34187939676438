import React, { useContext } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { Context } from "../../context/Store";

const AuthRoute = ({ children }) => {
  const {
    state: { user_data },
  } = useContext(Context);
  const isLogin = user_data.isLogin;

  const [searchParams] = useSearchParams();
  const location = useLocation();
  
  const nextPath = searchParams.get("next") ? searchParams.get("next") : "/";
  
  return !isLogin ? children : <Navigate to={nextPath} />;
};

export default AuthRoute;                        
